import React from 'react'
import image4 from '../Assets/imgs/inicio/image5.jpeg'
import { PersonCard } from '../Components/Cards'
import {Carousel} from "../Components/Carousel";
import { LineaTiempo } from '../Components/LineaTiempo';

export const Nosotros = () => {
  
    return (
        <main className="page">

            <Carousel />

            <LineaTiempo />

            {/* Años de experiencia */}
            

            <section className="clean-block about-us mt-5 mb-5">
                <div className="container">
                    <div className="block-heading">
                        <h2 className="text-info">About Us</h2>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc quam urna, dignissim nec auctor in, mattis vitae leo.</p>
                    </div>
                    <div className="row justify-content-center">
                        <div className="col-sm-6 col-lg-4">
                            <PersonCard
                              image={image4}
                              title={'John Smith'}
                              description={'Lorem ipsum dolor sit amet, consectetur adipisicing elit.'}
                            />
                        </div>
                        <div className="col-sm-6 col-lg-4">
                            <PersonCard
                              image={image4}
                              title={'John Smith'}
                              description={'Lorem ipsum dolor sit amet, consectetur adipisicing elit.'}
                            />
                        </div>
                        <div className="col-sm-6 col-lg-4">
                            <PersonCard
                              image={image4}
                              title={'John Smith'}
                              description={'Lorem ipsum dolor sit amet, consectetur adipisicing elit.'}
                            />
                        </div>
                    </div>
                </div>
            </section>

        </main> 
    )
}
