import React, { useEffect } from "react";
import { ScrollSection } from "./ScrollSection";
import { SimpleCard } from "./Cards";

export const LineaTiempo = () => {

    useEffect(() => {
        // define variables
        let items = document.querySelectorAll(".timeline li");

        // check if an element is in viewport
        // http://stackoverflow.com/questions/123999/how-to-tell-if-a-dom-element-is-visible-in-the-current-viewport
        function isElementInViewport(el) {
            let rect = el.getBoundingClientRect();
            return (
                rect.top >= 0 &&
                rect.left >= 0 &&
                rect.bottom <=
                (window.innerHeight || document.documentElement.clientHeight) &&
                rect.right <= (window.innerWidth || document.documentElement.clientWidth)
            );
        }

        function callbackFunc() {
            for (let i = 0; i < items.length; i++) {
                if (isElementInViewport(items[i])) {
                    items[i].classList.add("in-view");
                }
            }
        }

        // listen for events
        window.addEventListener("load", callbackFunc);
        window.addEventListener("resize", callbackFunc);
        window.addEventListener("scroll", callbackFunc);

    }, []);

  return (
    
      <section className="clean-block features mt-3">
        <div className="timeline">
          <ul className={""}>
            <li>
              <div>
                <ScrollSection>
                  <h4 className=""> Objetivo </h4>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Nunc quam urna, dignissim nec auctor in, mattis vitae leo.
                  </p>
                </ScrollSection>
              </div>
            </li>
            <li>
              <div>
                <ScrollSection>
                  <SimpleCard
                    icon={""}
                    title={"Misión"}
                    description={
                      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc quam urna, dignissim nec auctor in, mattis vitae leo."
                    }
                  />
                </ScrollSection>
              </div>
            </li>
            <li>
              <div>
                <ScrollSection>
                  <SimpleCard
                    icon={""}
                    title={"Visión"}
                    description={
                      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc quam urna, dignissim nec auctor in, mattis vitae leo."
                    }
                  />
                </ScrollSection>
              </div>
            </li>
            <li>
              <div>
                <ScrollSection>
                  <SimpleCard
                    icon={""}
                    title={"Compromiso"}
                    description={
                      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc quam urna, dignissim nec auctor in, mattis vitae leo."
                    }
                  />
                </ScrollSection>
              </div>
            </li>
            <li>
              <div>
                <ScrollSection>
                  <SimpleCard
                    icon={""}
                    title={"Valores"}
                    description={
                      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc quam urna, dignissim nec auctor in, mattis vitae leo."
                    }
                  />
                </ScrollSection>
              </div>
            </li>
          </ul>
        </div>
      </section>
    
  );
};
