import React from 'react'
import {BsFacebook, BsInstagram, BsTiktok, BsYoutube} from 'react-icons/bs'
import {MdOutlinePlace} from 'react-icons/md'
import {FaPhoneAlt, FaEnvelope} from 'react-icons/fa'
export const Contacto = () => {
  
    return (
        <main className="page contact-us-page">
            <section className="container" >

                <h2 className="section-header title-contact">Contactanos</h2>

                <div className="row mt-5">


                    <form id="contact-form" className="col-md-6 col-12" role="form">

                        <div className="form-floating mb-3">
                            <input type="text" className="form-control" id="name" placeholder="NAME" name="name" value="" required />
                            <label htmlFor="name"> Nombre </label>

                        </div>

                        <div className="form-floating mb-3">
                            <input type="email" className="form-control" id="email" placeholder="EMAIL" name="email" value="" required />
                            <label htmlFor="email"> Email </label>
                        </div>

                        <div className="form-floating mb-3">
                            <textarea className="form-control" rows="10" placeholder="MESSAGE" name="message" required style={{height: '100px'}}></textarea>
                            <label htmlFor="message">Comments</label>
                        </div>

                        <div className='d-flex justify-content-center w-100'>
                            <button className="btn btn-outline-success send-button w-50" id="submit" type="submit" value="Solicitar información">
                                <div className="alt-send-button">
                                    <i className="fa fa-paper-plane"></i><span className="send-text">Solicitar información</span>
                                </div>

                            </button>
                        </div>
                        
                    </form>


                    <div className="col-md-6 col-12">

                        <ul className="contact-list">
                            <li className="list-item">
                                <MdOutlinePlace className="fs-3"></MdOutlinePlace>
                                <span className="contact-text place">City, State</span>
                            </li>

                            <li className="list-item">
                                <FaPhoneAlt className="fs-3"></FaPhoneAlt>
                                <span className="contact-text phone">
                                    <a href="tel:1-212-555-5555" title="Give me a call">(212) 555-2368</a>
                                </span>
                            </li>

                            <li className="list-item">
                                <FaEnvelope className="fs-3"></FaEnvelope>
                                <span className="contact-text gmail">
                                    <a href="mailto:#" title="Send me an email">hitmeup@gmail.com</a>
                                </span>
                            </li>
                        </ul>

                        <hr />

                        <ul className="social-media-list">
                            <li>
                                <a href="#" target="_blank" className="contact-icon">
                                    <BsFacebook className={'fs-2 m-0 p-0'} />
                                </a>
                            </li>
                            <li>
                                <a href="#" target="_blank" className="contact-icon">
                                    <BsInstagram className={'fs-2 m-0 p-0'} />
                                </a>
                            </li>
                            <li>
                                <a href="#" target="_blank" class="contact-icon">
                                    <BsTiktok className={'fs-2 m-0 p-0'} />
                                </a>
                            </li>
                            <li>
                                <a href="#" target="_blank" class="contact-icon">
                                    <BsYoutube className={'fs-2 m-0 p-0'} />
                                </a>
                            </li>
                        </ul>

                        <hr />

                        <div class="copyright">&copy; ALL OF THE RIGHTS RESERVED</div>

                    </div>

                </div>

            </section>

        </main>
    )
}
