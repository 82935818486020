import React from 'react'
import image4 from '../Assets/imgs/inicio/image5.jpeg';
import { InfoCard } from '../Components/Cards'
import { ScrollSection } from '../Components/ScrollSection';
import { FullViewScreen } from '../Components/FullViewScreen';

export const Servicios = () => {
  
    return (
        <main class="page service-page">
            <section class="clean-block clean-services dark">
                <div class="">
                    <div class="block-heading">
                        <h2 class="title-service-page">Service Page</h2>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc quam urna, dignissim nec auctor in, mattis vitae leo.</p>
                    </div>
                    <div class="row">
                    
                        {
                            [1,2,3,4,5,6].map((item, index) => (
                                /* <div class="">
                                    <InfoCard
                                        image={image4}
                                        title={'Servicio ' + item}
                                        description={'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc quam urna, dignissim nec auctor in.'}
                                        nameBtn={'Ver más'}
                                        actionBtn={()=>{}}
                                    />
                                </div> */
                                <ScrollSection>
                                    <FullViewScreen
                                        img={image4}
                                        title={'Servicio ' + item}
                                        text={'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc quam urna, dignissim nec auctor in.'}
                                        around={ index % 2 === 0 ? 'left' : 'right' }
                                    />
                                </ScrollSection>
                                                ))
                        }

                    </div>
                </div>
            </section>
        </main>
    )
}
