

export const SimpleCard = ({icon, title, description}) => {

    // feature-box
    return (
        <>
            <i className="icon-star icon"> {icon} </i>
            <h4>{title}</h4>
            <p>{description}</p>
        </>
    )
}