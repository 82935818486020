import React from "react";
import { useId } from "react";

export const FullViewScreen = ({ img, title, text, around }) => {
  const id = useId();
  console.log(id);

  return (
    <div
      className=""
      style={{
        position: "relative",
        height: "100vh",
        width: "98.1vw",
        padding: "0",
        margin: 0,
      }}
    >
      <svg
        viewBox="0 0 200 200"
        xmlns="http://www.w3.org/2000/svg"
        style={{
          position: "absolute",
          top: "0",
          left: "0",
          right: "0",
          bottom: "0",
          zIndex: "-1",
          width: "100%",
          height: "100%",
        }}
      >
        <path
          fill="rgba(0, 156, 148, 0.3)"
          d="M39,-64.1C50.4,-60.9,59.4,-50.2,68.5,-38.3C77.7,-26.3,87,-13.2,83.3,-2.1C79.6,8.9,62.9,17.8,54,30.2C45.2,42.7,44.2,58.8,36.5,64C28.8,69.2,14.4,63.6,0.2,63.3C-14.1,63,-28.1,68,-36.4,63.2C-44.8,58.3,-47.3,43.6,-54.3,31.4C-61.2,19.2,-72.5,9.6,-76.9,-2.6C-81.4,-14.7,-78.9,-29.4,-71.9,-41.5C-64.9,-53.6,-53.4,-63,-40.6,-65.4C-27.9,-67.8,-13.9,-63.2,-0.1,-63.1C13.8,-63,27.6,-67.3,39,-64.1Z"
          transform="translate(100 100)"          
        />
      </svg>

      <div className="row " style={{ width: "100p%", height: "100%" }}>
        <div className="col-12 col-md-6">
          <div className="d-flex justify-content-center m-auto h-100">
            {around === "left" ? (
              <div className="d-flex flex-column justify-content-center align-middle ">
                <h2 className="title_full_view_scren" >{title}</h2>
                <h3 className="description_full_view_scren ">{text}</h3>
              </div>
            ) : (
              <div style={styles.contenedorImg}>
                <img
                  src={img}
                  alt={title}
                  className="img-servcios "
                  style={styles.img}
                />
              </div>
            )}
          </div>
        </div>

        <div className="col-12 col-md-6">
          <div className="d-flex justify-content-center m-auto h-100">
            {around === "right" ? (
              <div className="d-flex flex-column justify-content-center align-middle ">
                <h2 className="title_full_view_scren">{title}</h2>
                <h3 className="description_full_view_scren ">{text}</h3>
              </div>
            ) : (
              <div style={styles.contenedorImg}>
                <img
                  src={img}
                  alt={title}
                  className="img-servcios "
                  style={styles.img}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const styles = {
  contenedorImg: {
    height: "100%",
    width: "100%",
    padding: "0",
    margin: 0,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    // backgroundColor: "black",
  },
  img: {
    height: "100%",
    width: "100%",
    objectFit: "cover",
    objectPosition: "center",
    maxWidth: "100%",
    maxHeight: "100%",
    borderRadius: "20px",
    boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.75)",
    // centrar imagen
    display: "flex",
    margin: "auto",
    justifyContent: "center",
    alignItems: "center",
  },
};
