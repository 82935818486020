import image4 from '../Assets/imgs/inicio/image5.jpeg';
import image5 from '../Assets/imgs/scenery/image5.jpg';

export const Carousel = () => {

    return (
        <section className="clean-block slider dark mt-4">
            <div className="">
                <div className="carousel slide" data-bs-ride="carousel" id="carousel-1">
                    <div className="carousel-inner">
                        <div className="carousel-item active">
                            <img className="w-100 d-block" src={image4} alt="Slide Image"/>
                        </div>
                        <div className="carousel-item">
                            <img className="w-100 d-block" src={image5} alt="Slide Image"/>
                        </div>
                        <div className="carousel-item">
                            <img className="w-100 d-block" src={image4} alt="Slide Image"/>
                        </div>
                    </div>
                    <div><a className="carousel-control-prev" href="#carousel-1" role="button" data-bs-slide="prev"><span className="carousel-control-prev-icon"></span><span className="visually-hidden">Previous</span></a><a className="carousel-control-next" href="#carousel-1" role="button" data-bs-slide="next"><span className="carousel-control-next-icon"></span><span className="visually-hidden">Next</span></a></div>
                    <ol className="carousel-indicators">
                        <li data-bs-target="#carousel-1" data-bs-slide-to="0" className="active"></li>
                        <li data-bs-target="#carousel-1" data-bs-slide-to="1"></li>
                        <li data-bs-target="#carousel-1" data-bs-slide-to="2"></li>
                    </ol>
                </div>
            </div>
        </section>
    )
} 