import React from 'react'
import { Navigate, Route, Routes } from 'react-router'
import { Inicio } from '../Pages/Inicio'
import { Nosotros } from '../Pages/Nosotros'
import { Servicios } from '../Pages/Servicios'
import { Galeria } from '../Pages/Galeria'
import { Contacto } from '../Pages/Contacto'
import { Precios } from '../Pages/Precios'

export const Router = () => {
  
    return (
        <Routes>
            <Route path="/" element={<Inicio />} />
            <Route path="/nosotros" element={<Nosotros />} />
            <Route path='/precios' element={<Precios />} />
            <Route path="/servicios" element={<Servicios />} />
            <Route path="/galeria" element={<Galeria />} />
            <Route path="/contacto" element={<Contacto />} />

            <Route path="/*" element={<Navigate to="/" />} />
        </Routes>
    )
}
