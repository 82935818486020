import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import SVGIcon from "../Assets/imgs/logos/1.svg";

export const Header = () => {
  const [openNav, setOpenNav] = useState(false);

  // Cuando el usuario de clic algun elemento del menu se cierra el menu
  const handleNav = () => {
    setOpenNav(!openNav);
  };

  return (
    <nav className="navbar navbar-light navbar-expand-lg fixed-top">
      <div className="container">
        <NavLink className="navbar-brand logo p-0 m-0" to="/">
          <img
            src={SVGIcon}
            alt="Logo"
            width={70}
            style={{ margin: 0, padding: 0, position: "absolute", top: 0 }}
          />
        </NavLink>
        <button
          data-bs-toggle="collapse"
          className={"navbar-toggler " + (openNav ? "collapsed" : "")}
          data-bs-target="#navcol-1"
          onClick={handleNav}
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div
          className={"collapse navbar-collapse" + (openNav ? " show" : "")}
          id="navcol-1"
        >
          <ul className="navbar-nav ms-auto">
            <li className="nav-item">
              <NavLink className="nav-link active" to="/" onClick={handleNav}>
                Inicio
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink className="nav-link" to="/nosotros" onClick={handleNav}>
                Nosotros
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink className="nav-link" to="/servicios" onClick={handleNav}>
                Servicios
              </NavLink>
            </li>
            {/* <li className="nav-item">
                            <NavLink className="nav-link" to="/">
                                Features
                            </NavLink>
                        </li> */}
            {/* <li className="nav-item">
                            <NavLink className="nav-link" to="/precios">
                                Precios
                            </NavLink>
                        </li> */}
            <li className="nav-item">
              <NavLink className="nav-link" to="/galeria" onClick={handleNav}>
                Galeria
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink className="nav-link" to="/contacto" onClick={handleNav}>
                Contactanos
              </NavLink>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};
