import React from 'react';
import ImageGallery from 'react-image-gallery';
import image5 from '../Assets/imgs/inicio/image5.jpeg';

const images = [
  {
    original: image5,
    thumbnail: image5,
  },
  {
    original: "https://picsum.photos/id/1015/1000/600/",
    thumbnail: "https://picsum.photos/id/1015/250/150/",
  },
  {
    original: "https://picsum.photos/id/1019/1000/600/",
    thumbnail: "https://picsum.photos/id/1019/250/150/",
  },
];

export const Galeria = () => {
  
  return (
    <div>
      <ImageGallery 
        items={images}
        additionalClass="app-image-gallery"
        
      />;  
    </div>
  )
}
