import React from 'react'

export const Precios = () => {
  
    return (
        <main class="page pricing-table-page">
            <section class="clean-block clean-pricing dark">
                <div class="container">
                    <div class="block-heading">
                        <h2 class="text-info">Pricing Table</h2>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc quam urna, dignissim nec auctor in, mattis vitae leo.</p>
                    </div>
                    <div class="row justify-content-center">
                        <div class="col-md-5 col-lg-4">
                            <div class="clean-pricing-item">
                                <div class="heading">
                                    <h3>BASIC</h3>
                                </div>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                                <div class="features">
                                    <h4><span class="feature">Full Support:&nbsp;</span><span>No</span></h4>
                                    <h4><span class="feature">Duration:&nbsp;</span><span>30 Days</span></h4>
                                    <h4><span class="feature">Storage:&nbsp;</span><span>10GB</span></h4>
                                </div>
                                <div class="price">
                                    <h4>$25</h4>
                                </div><button class="btn btn-outline-primary d-block w-100" type="button">BUY NOW</button>
                            </div>
                        </div>
                        <div class="col-md-5 col-lg-4">
                            <div class="clean-pricing-item">
                                <div class="ribbon"><span>Best Value</span></div>
                                <div class="heading">
                                    <h3>PRO</h3>
                                </div>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                                <div class="features">
                                    <h4><span class="feature">Full Support:&nbsp;</span><span>Yes</span></h4>
                                    <h4><span class="feature">Duration:&nbsp;</span><span>60 Days</span></h4>
                                    <h4><span class="feature">Storage:&nbsp;</span><span>50GB</span></h4>
                                </div>
                                <div class="price">
                                    <h4>$50</h4>
                                </div><button class="btn btn-primary d-block w-100" type="button">BUY NOW</button>
                            </div>
                        </div>
                        <div class="col-md-5 col-lg-4">
                            <div class="clean-pricing-item">
                                <div class="heading">
                                    <h3>PREMIUM</h3>
                                </div>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                                <div class="features">
                                    <h4><span class="feature">Full Support:&nbsp;</span><span>Yes</span></h4>
                                    <h4><span class="feature">Duration:&nbsp;</span><span>120 Days</span></h4>
                                    <h4><span class="feature">Storage:&nbsp;</span><span>150GB</span></h4>
                                </div>
                                <div class="price">
                                    <h4>$150</h4>
                                </div><button class="btn btn-outline-primary d-block w-100" type="button">BUY NOW</button>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </main>    
    )
}
