import { useEffect } from "react";
import { ReactTyped } from "react-typed";

export const PageMain_v2 = ({stringTyped, img}) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div
      className="row light-effect align-items-center m-auto"
      style={{
        height: "100vh",
        backgroundImage: `url("${img}")`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        //   position: "absolute",
        //   top: "13vh",
        //   left: "20vw",
        //   right: "20vw",
        //   bottom: "15vh",
        zIndex: "1",
      }}
    >
      <h1
        className="russo-one-regular col-12"
        style={{
          //   color: "#001120",
          //   width: "90%",
          //   margin: "auto",
          fontSize: "5vw",
        //   display: "flex",
        //   justifyContent: "center",
          textAlign: "center",
        }}
      >
        Electrobobinados Ruiz
      </h1>

      <p
        className="amaranth-regular-italic col-12"
        style={{
          //   color: "rgba(0, 0, 0, 1)",
          // fontSize: "3vw",
          fontWeight: "bold",
        //   width: "90%",
        //   margin: "auto",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <ReactTyped
          strings={[stringTyped]}
          style={{
            fontSize: "25px",
            fontWeight: "bold",
            // color: "#009c94",
          }}
          typeSpeed={40}
          backSpeed={50}
          
          // attr="placeholder"
          loop
        />
      </p>

      <div className="col-12">
        <button
          className="btn border border-1 btn-lg"
          type="button"
          style={{
            display: "flex",
            justifyContent: "center",
            margin: "auto",
            // color: "#009c94",
            // width: "50%",
            // marginTop: "50px",
          }}
        >
          Ver catálogo
        </button>
      </div>
    </div>
  );
};
