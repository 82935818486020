import React, { useEffect } from "react";

import image4 from "../Assets/imgs/inicio/image5.jpeg";
import image5 from "../Assets/imgs/scenery/image5.jpg";
import { Carousel } from "../Components/Carousel";
import { SimpleCard, PersonCard } from "../Components/Cards";
import { AnimationScroll } from "../Components/AnimationScroll";
import { ScrollSection } from "../Components/ScrollSection";
import { FullViewScreen } from "../Components/FullViewScreen";
import { PageMain } from "../Components/PageMain";
import { PageMain_v2 } from "../Components/PageMain_v2";
import { Swiper, SwiperSlide } from "swiper/react";
import { EffectFade, Autoplay } from "swiper/modules";
import IMG1 from "../Assets/imgs/swipper_photos/1.png";
import IMG2 from "../Assets/imgs/swipper_photos/2.png";
import IMG3 from "../Assets/imgs/swipper_photos/3.png";

import "swiper/css/autoplay";
import "swiper/css/effect-fade";

export const Inicio = () => {
  return (
    <main className="page landing-page">
      <Swiper
        spaceBetween={50}
        slidesPerView={1}
        speed={1500}
        autoplay={{
          delay: 2000,
          disableOnInteraction: false,
        }}
        loop={true}
        effect="fade"
        modules={[EffectFade, Autoplay]}
        onSlideChange={() => console.log("slide change")}
        onSwiper={(swiper) => console.log(swiper)}
      >
        <SwiperSlide>
          <PageMain_v2 img={IMG1} stringTyped={"Transformadores"} />
        </SwiperSlide>
        <SwiperSlide>
          <PageMain_v2 img={IMG2} stringTyped={"Arrancadores"} />
        </SwiperSlide>
        <SwiperSlide>
          <PageMain_v2 img={IMG3} stringTyped={"Motores eléctricos"} />
        </SwiperSlide>
      </Swiper>
      {/* <section className="clean-block clean-hero" style={{backgroundImage: `url(${image4})`, color: "rgba(255, 250, 100, .85)", height: '100vh', backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundPosition: 'center'}}>
                <div className="text bg-dark opacity-75 text-center d-flex flex-column justify-content-center h-100 m-auto">
                    <div className='w-50 m-auto'>
                        <ScrollSection>
                           <h1 className='fs-1 text-center '> Taller Electrico Ruiz .</h1>
                        </ScrollSection>
                        <ScrollSection>
                            <p className='fs-4 '>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc quam urna, dignissim nec auctor in, mattis vitae leo.
                            </p>
                        </ScrollSection>
                        <button className="btn btn-outline-light btn-lg scale-up-top" type="button">
                            Ver cátalogo
                        </button>
                    </div>
                </div>
            </section> */}

      {/* <ScrollSection> */}
      <div
        className=" d-flex flex-column text-center justify-content-center"
        style={{
          height: "400px",
          // background: "rgba(0, 0, 0, 0.1)",
          margin: "0",
          padding: "0",
        }}
      >
        <div className="leyenda animate__animated animate__backInLeft animate__bounce ">
          <h3
            className="leyenda-title-right p-4"
            // style={{ color: "yellow", fontWeight: "bold", fontSize: "2rem" }}
          >
            ¡Siempre a tu servicio!
          </h3>
        </div>
        <div className="leyenda animate__animated animate__backInRight animate__bounce ">
          <h3
            className="leyenda-title-left p-4"
            // style={{ color: "yellow", fontWeight: "bold", fontSize: "2rem" }}
          >
            !Dispuestos a ayudarte en lo que necesites!
          </h3>
        </div>
        {/* <div>
          <button className="btn btn-outline-danger btn-lg" type="button">
            Ir a los servicios
          </button>
        </div> */}
      </div>
      {/* </ScrollSection> */}

      {/* <ScrollSection> */}
      {/* <section className="clean-block clean-info dark mt-5 mb-5 " >
                    <div className="container">
                        <div className="block-heading ">
                            <h2 className="text-info">Info</h2>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc quam urna, dignissim nec auctor in, mattis vitae leo.</p>
                        </div>
                        <div className="row align-items-center">
                            <div className="col-md-6">
                                <img className=" img-thumbnail" src={image4} alt=''/>
                            </div>
                            <div className="col-md-6">
                                <h3>Lorem impsum dolor sit amet</h3>
                                <div className="getting-started-info ">
                                    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p>
                                </div>
                                <div className='d-flex justify-content-end mx-4'>
                                    <button className="btn btn-outline-primary btn-lg" type="button">Conocer más</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </section> */}
      {/* </ScrollSection> */}

      {/* <ScrollSection>
        <hr />
      </ScrollSection> */}

      <ScrollSection>
        <FullViewScreen
          img={image4}
          title={"Taller Electrico Ruiz"}
          text={
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc quam urna, dignissim nec auctor in, mattis vitae leo."
          }
          around={"left"}
        />
      </ScrollSection>

      <ScrollSection>
        <FullViewScreen
          img={image4}
          title={"Taller Electrico Ruiz"}
          text={
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc quam urna, dignissim nec auctor in, mattis vitae leo."
          }
          around={"right"}
        />
      </ScrollSection>

      <ScrollSection>
        <FullViewScreen
          img={image4}
          title={"Taller Electrico Ruiz"}
          text={
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc quam urna, dignissim nec auctor in, mattis vitae leo."
          }
          around={"left"}
        />
      </ScrollSection>

      <ScrollSection>
        <hr />
      </ScrollSection>

      <div className="container">
        {/*<div className="block-heading">
                    <h2 className="text-info"> Carousel </h2>
                    <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc quam urna, dignissim nec auctor in, mattis vitae leo.
                    </p>
                </div>*/}
      </div>
      <Carousel />

      <section className="clean-block about-us mt-5 mb-5">
        <div className="container">
          <div className="block-heading">
            <h2 className="text-info"> Somos... </h2>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc quam
              urna, dignissim nec auctor in, mattis vitae leo.
            </p>
          </div>

          <div className="row justify-content-center">
            <div className="col-sm-6 col-lg-4">
              <PersonCard
                image={image4}
                title={"John Smith"}
                description={
                  "Lorem ipsum dolor sit amet, consectetur adipisicing elit."
                }
              />
            </div>
            <div className="col-sm-6 col-lg-4">
              <PersonCard
                image={image4}
                title={"Robert Smith"}
                description={
                  "Lorem ipsum dolor sit amet, consectetur adipisicing elit."
                }
              />
            </div>
            <div className="col-sm-6 col-lg-4">
              <PersonCard
                image={image4}
                title={"Josh Smith"}
                description={
                  "Lorem ipsum dolor sit amet, consectetur adipisicing elit."
                }
              />
            </div>
          </div>
        </div>
      </section>
    </main>
  );
};
